<template>
  <div>
    <div class="flex md12">
      <va-card :title="'Completed Exams'">
        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              v-model="term"
              :placeholder="'Search by name or date'"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3">
              <va-select
                label="Filters"
                v-model="filterModel"
                text-by="label"
                key-by="value"
                @input="filterBy"
                :options="filterOptions"
              />
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
          <va-data-table
            v-if="notSearching"
            :fields="fields"
            :data="completedExams"
            api-mode
            :loading="loading"
            :per-page="parseInt(perPage)"
            :totalPages="totalPages"
            @page-selected="handlePageChange"
            @row-clicked="showUser"
            hoverable
            clickable
          />
        <va-card v-else>
          <div class="text-center">
           <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        </va-card>
      </va-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import axios from '@/scripts/interceptor.js'
import moment from 'moment';
export default {
  name: 'ServerSide',
  /**
   * @description Setup the data for the component
  */
  data() {
    return {
      loading: true,
      completedExams: [],
      term: "",
      notSearching: true,
      pageSnapshots: [],
      cachedPages: {},
      //current page is the current page number
      currentPage: 1,
      //total pages is the pagination number [1,2,3,4,5...]
      totalPages: 5,
      //per page is the amount of items per page
      perPage: '10',
      perPageOptions: ['5','10', '50', '100', '250'],
      filterModel: '',
      filterLastOrder: '',
      filterOptions: [
        {label: 'Name', value: 'name'},
        {label: 'Score', value: 'score'},
        {label: 'Passed', value: 'passed'},
        {label: 'Exam Name', value: 'examname'},
     ],
    };
  },
  async created() {
    await this.getPagination();
    await this.fetchCompletedExams(this.currentPage, this.perPage);
  },
  /**
   * @description Watch for changes in the pagination and update the table
 */
  watch: {
    currentPage: {
      handler: function () {
        if(!this.notSearching) return;
        this.fetchCompletedExams(this.currentPage, this.perPage);
      },
    },
    term: {
      handler: function () {
        this.debouncedSearch();
      },
    },
    perPage: {
      handler: async function () {
        this.resetCache();
        await this.getPagination();
        if (this.term) {
          this.searchCompletedExams(this.term, 0, this.perPage);
        } else {
          await this.fetchCompletedExams(this.currentPage, this.perPage);
        }
      },
    },
  },
  /**
   * @description Handle/Fetch the page change event
  */
  methods: {
    convertScore(score, letters) {
      const scoreString = score.toString().replace('.', '');
      return [...scoreString].map(score => letters[score]).join('');
    },
    formatDate(seconds) {
      const dateObj = new Date(seconds * 1000);
      return moment(dateObj).format('MM/DD/yy, h:mm:ss a');
    },
    filterAndSortUsers(exams, selectedFilter, order) {
      // Map the selected filter to the corresponding property/key in the exam object
      const propertyMap = {
        name: 'user',
        score: 'score',
        passed: 'passed',
        examname: 'examName',
      };
      // Get the property/key corresponding to the selected filter
      const selectedProperty = propertyMap[selectedFilter];

      // Sort the exams array based on the selected filter and order
      const sortedExams = exams.sort((a, b) => {
        const valueA = a[selectedProperty] ? (a[selectedProperty]).toString() :'';
        const valueB = b[selectedProperty] ? (b[selectedProperty]).toString() : '';
        if (order === 'asc') {
          if (selectedFilter === 'passed') {
            // Convert the "passed" property to a boolean for correct sorting
            const passedA = valueA === 'true';
            const passedB = valueB === 'true';
            return passedA - passedB;
          } else {
            return valueA.localeCompare(valueB);
          }
        } else if (order === 'desc') {
          if (selectedFilter === 'passed') {
            // Convert the "passed" property to a boolean for correct sorting
            const passedA = valueA === 'true';
            const passedB = valueB === 'true';
            return passedB - passedA;
          } else {
            return valueB.localeCompare(valueA);
          }
        }
      });

      return sortedExams;
    },

    async filterBy() {
      let selected = this.filterModel.value;
      this.filterLastOrder = '';
      if (!selected) return;

      // Open a swal to the user select if he wants ascending or descending
      let { value: order } = await this.$swal({
        title: 'Order',
        input: 'select',
        inputOptions: {
          'asc': 'Ascending',
          'desc': 'Descending',
        },
        inputPlaceholder: 'Select an order',
        showCancelButton: true,
      });
      this.filterLastOrder = order;
      // Sort the exams array based on the selected filter and order
      const filteredAndSortedExams =  this.filterAndSortUsers(this.completedExams, selected, order);
      this.completedExams = filteredAndSortedExams; 
    },
    showUser(exam) {
      this.$router.push({ name: 'edituser', params: { uid: exam.id } })
    },
    /**
     * @description Performs a debounced search or resets the exam list when the search term changes.
     * When a search term is present, it searches for exams with the given term.
     * Otherwise, it resets the cache, gets pagination, and fetches the first page of exams.
     * The debounce delays the search by 500ms to minimize the number of requests triggered by rapid input changes.
    */
    debouncedSearch: _.debounce(async function () {
      if (this.term) {
        this.notSearching = false;
        await this.searchCompletedExams(this.term, 0, this.perPage);
        this.notSearching = true;
      } else {
        this.notSearching = false;
        this.resetCache();
        await this.getPagination();
        await this.fetchCompletedExams(1, this.perPage);
        this.notSearching = true;
      }
    }, 500),
    /**
     * @description Resets the cache for storing fetched exam pages and their boundaries.
     * Clears the 'cachedPages' object and 'pageSnapshots' array to remove previously fetched data and make room for new data.
    */
    resetCache() {
      this.cachedPages = {};
      this.pageSnapshots = [];
    },
    /**
     * @description Handles the page change event for the exam list.
     * If there's a search term, perform a search for the new page.
     * Otherwise, update the current page number.
     * @param {number} page - The target page number to change to.
    */
    handlePageChange(page) {
      if (this.term) {
        this.searchCompletedExams(this.term, (page - 1), this.perPage);
      }else{
        this.currentPage = page;
      }
    },
    /**
     * @description Searches exams based on the given search term and fetches the specified page of results.
     * Calls the 'admin-searchCompletedExams' Firebase function with the search term, items per page, and page number.
     * Updates the component's data with the received search results and total pages.
     *
     * @param {string} searchTerm - The search term used to filter exams.
     * @param {number} page - The target page number to fetch.
     * @param {number} itemsPerPage - The number of items to display per page.
    */
    async searchCompletedExams(searchTerm, page, itemsPerPage) {
      this.loading = true;
      try {
        const result = await axios.post(window.firebaseURL + `api/admin/searchCompletedExams`,{
          search: searchTerm,
          hitsPerPage: parseInt(itemsPerPage),
          page: page,
        });
        let fetchedExams = []
        for (const doc of result?.data?.data.hits) {
          if (doc.Test && doc.Test.Models && doc.Test.questions.length > 0 && doc.Test.Active) {
            const date = this.formatDate(doc.TestCompleted);
            fetchedExams.push({
              user: doc.UserEmail ? doc.UserEmail : 'N/A',
              id: doc.UserId,
              score: doc.Score,
              passed: doc.Passed.toString(),
              completed: date,
              examName: doc.Test.Name,
            });
          }
        }
        this.completedExams = this.filterModel && this.filterLastOrder ? this.filterAndSortUsers(fetchedExams, this.filterModel?.value, this.filterLastOrder) : fetchedExams;
        this.totalPages = result?.data?.data?.totalPages;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    /**
   * @description Calculate the total number of pages for pagination based on the total number of items and items per page.
   * Fetches the total number of exams from the Firestore 'exams' collection, then calculates the number of pages needed for pagination.
   * The result is stored in the component's data.
   */
    async getPagination() {
        let examsTable = firebase.firestore().collection('completedExams').where('Test.Active', '==', true)
        let querySnapshot = await examsTable.get();
        const totalItems = querySnapshot.size;
        const itemsPerPage = parseInt(this.perPage);
        this.totalPages = Math.floor(totalItems / itemsPerPage);
     },
     /**
     * Fetches a page of exams and stores the results in the component's data.
     * Utilizes caching to minimize the number of queries to the Firestore.
     *
     * @param {number} page - The target page number to fetch.
     * @param {number} itemsPerPage - The number of items to display per page.
     * @param {string} search - If the search is active.
    */
    async fetchCompletedExams(page, itemsPerPage, search = '') {
      this.loading = true;
      this.completedExams = [];
      const pageSize = parseInt(itemsPerPage);
      const targetPage = page;

      // Check if the requested page is already in the cache
      if (this.cachedPages[targetPage] && !search) {
        this.completedExams = this.filterModel && this.filterLastOrder ? this.filterAndSortUsers(this.cachedPages[targetPage], this.filterModel?.value, this.filterLastOrder) : this.cachedPages[targetPage];
        this.loading = false;
        return;
      }

      let examsTable = firebase.firestore().collection('completedExams').where('Test.Active', '==', true)
      let examsQuery = examsTable.orderBy('TestCompleted', 'desc').limit(pageSize);

      // Use the cached boundaries, if available
      if (targetPage > 1) {
        if (this.pageSnapshots[targetPage - 1]) {
          // Reuse the stored snapshot for the previous page
          examsQuery = examsQuery.startAfter(this.pageSnapshots[targetPage - 1].end);
        } else {
          const skips = (targetPage - 1) * pageSize;
          const lastVisible = await examsTable.orderBy('TestCompleted', 'desc').limit(skips).get().then((snapshot) => {
            return snapshot.docs[snapshot.docs.length - 1];
          });
          examsQuery = examsQuery.startAfter(lastVisible);
        }
      }

      let querySnapshot = await examsQuery.get();
      
      const fetchedExams = [];

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        if (data.Test && data.Test.Models && data.Test.questions.length > 0 && data.Test.Active) {
          const date = this.formatDate(data.TestCompleted.seconds);
          fetchedExams.push({
            user: data.UserEmail ? data.UserEmail : 'N/A',
            id: data.UserId,
            score: data.Score,
            passed: data.Passed.toString(),
            completed: date,
            examName: data.Test.Name,
          });
        }
      }
      this.cachedPages[targetPage] = fetchedExams;
      this.completedExams = this.filterModel && this.filterLastOrder ? this.filterAndSortUsers(fetchedExams, this.filterModel?.value, this.filterLastOrder) : fetchedExams;
      this.pageSnapshots[targetPage] = {
        start: querySnapshot.docs[0],
        end: querySnapshot.docs[querySnapshot.docs.length - 1],
      };
      this.loading = false;
    }
  },  
  /**
   * Setup Table/Filters
  */
  computed: {
    fields() {
      return [{
        name: 'user',
        title: 'Name',
        sortField: 'user',
        width: '20%',
      }, {
        name: 'score',
        title: 'Score',
        width: '20%',
      },
      {
        name: 'passed',
        title: 'Passed',
        width: '20%',
      },
      {
        name: 'completed',
        title: 'Completed Date',
      },
      {
        name: 'examName',
        title: 'Exam Name',
        width: '10%',
      },
      ];
    },
  },
};
</script>
